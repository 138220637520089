(function ($) {

	'use strict';

	// http://snippetrepo.com/snippets/basic-vanilla-javascript-throttlingdebounce
	var debounce = function (func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			clearTimeout(timeout);
			timeout = setTimeout(function() {
				timeout = null;
				if (!immediate) {
					func.apply(context, args);
				}
			}, wait);
			if (immediate && !timeout) {
				func.apply(context, args);
			}
		};
	};

	$(function () {

		$.extend($.expr[':'], {
			external: function (el) { //, index, selector) {

				var regExp = new RegExp("//" + location.host + "($|/)");
				var href = $(el).attr('href');
				
				if (!href) {
					return false;
				}

				var isLocal = (href.substring(0, 4) === "http") ? regExp.test(href) : true;

				return !isLocal;

			}, 

			bookmark: function (el) { //, index, selector) {

				var href = $(el).attr('href');
				var isBookmark = href.indexOf('#') === 0;

				return isBookmark;
			}, 

			file: function (el) { //, index, selector) {

				var regExp = new RegExp('/[\\w\\s\\d-\\,_]+\\.[\\w\\d]{3,}$');
				var href = $(el).attr('href');
				var isFile = regExp.test(href);

				return isFile;

			}
		});

	});


	// https://davidwalsh.name/pubsub-javascript
	var eventBus = (function () {
		var topics = {};

		return {
			subscribe: function (topic, listener) {
				// Create the topic's object if not yet created
				if (!topics.hasOwnProperty.call(topics, topic)) {
					topics[topic] = [];
				}

				// Add the listener to queue
				var index = topics[topic].push(listener) - 1;

				// Provide handle back for removal of topic
				return {
					remove: function () {
						delete topics[topic][index];
					}
				};
			},
			publish: function (topic, info) {
				// If the topic doesn't exist, or there's no listeners in queue, just leave
				if (!topics.hasOwnProperty.call(topics, topic)) {
					return;
				}

				// Cycle through topics queue, fire!
				topics[topic].forEach(function (item) {
					//item(info !== undefined ? info : {});
					item.apply(null, info);
				});
			}
		};
	})();

	var commonEvents = {
		loading: 'rw.common.loading',
		loaded: 'rw.common.loaded',
		error: 'rw.common.error',
		pageLoading: 'rw.common.pageLoading',
		pageLoaded: 'rw.common.pageLoaded',
		contentReplaced: 'rw.common.contentReplaced',
		imagesLoaded: 'rw.common.imagesLoaded'
	};

	var tracking = (function () {

		var events = {
			trackPage: 'rw.tracking.page',
			trackEvent: 'rw.tracking.event'
		};

		var defaults = {
		};

		var settings = {};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			eventBus.subscribe(events.trackEvent, trackEvent);
			eventBus.subscribe(events.trackPage, trackPage);
		};

		var trackEvent = function (category, action, label, value) {
			if (!window.ga) {
				return;
			}

			try {
				window.ga('send', 'event', category, action, label, value);
			}
			catch (err) { }
		};

		var trackPage = function (url) {
			if (!window.ga) {
				return;
			}

			try {
				window.ga('send', 'pageview', url);
			}
			catch (err) { }
		};

		return {
			events : events,
			initialize: initialize,
			trackPage: trackPage,
			trackEvent: trackEvent
		};

	})();

	var pageLoader = (function () {

		var defaults = {
			timeout: 15000,
			contentSelector: '#content',
			metaSelector: 'meta'
		};

		var settings = {};

		var $doc = null;

		var $head = null;

		var initialize = function (options) {

			eventBus.publish(commonEvents.loading);

			settings = $.extend({}, defaults, options);

			$doc = $(document);
			$head = $('head', $doc);

			$doc.on('click', 'a', function (e) {
				var $el = $(this);
				var url = $el.attr('href');
				if ((url.length === 0) || $el.is(':external') || $el.is(':bookmark') || $el.is(':file')) {
					return;
				}

				if (window.event.ctrlKey)
				{
					return;
				}

				if ($el.data('skip-ajax'))
				{
					return;
				}

				e.preventDefault();
				load(url, null, null, false);
			});

			eventBus.subscribe(history.events.pop, function (state) {
				load(state.url, state.title, state.content, true);
			});

			window.history.replaceState({ title: document.title, url: document.location.href, content: null }, document.title, document.location.href);

			eventBus.publish(commonEvents.contentReplaced);

			$('body').imagesLoaded(function () {
				//console.log('onload body imagesloaded');
				eventBus.publish(commonEvents.imagesLoaded);
				eventBus.publish(commonEvents.loaded);
			});
		};

		var replaceContent = function (content) {

			var $content = $(settings.contentSelector, $doc);
			var $canonicalElem = $('link[rel=canonical]', $head);

			var $response = $('<div/>').html(content);
			var $responseAsPage = $('<html/>').html(content);
			var $titleElem = $response.find('title');
			var title = $titleElem.text().replace(/^\s+|\s+$/g, '');

			var $newCanonical = $responseAsPage.find('link[rel=canonical]');

			if ($canonicalElem.length) {
				if ($newCanonical.length) {
					$canonicalElem.replaceWith($newCanonical);
				}
				else {
					$canonicalElem.remove();
				}
			}
			else {
				$head.append($newCanonical);
			}

			var $newContent = $response.find(settings.contentSelector);

			if ($newContent.length === 0)
			{
				eventBus.publish(commonEvents.error, ['Oops. No new content found. ', 0]);
				return;
			}

			// Can't do $content globally as it's replaced each time
			$content.replaceWith($newContent);
			document.title=title;

			// Other meta data
			$(settings.metaSelector, $head).remove();
			var $newMetaData = $response.find(settings.metaSelector);
			$head.append($newMetaData);

			//console.log('content replaced');
			eventBus.publish(commonEvents.contentReplaced);

			$('html,body').animate({
				scrollTop: 0
			}, 250);

			// title returned so that we can store it in the history
			return title;
		};

		var load = function (url, title, content, ignoreHistory) {

			eventBus.publish(commonEvents.pageLoading);
			eventBus.publish(commonEvents.loading);

			if (content !== null)
			{
				//console.log('content replaced using history');

				eventBus.publish(commonEvents.pageLoaded, [url]);

				replaceContent(content);

				$(settings.contentSelector, $doc).imagesLoaded(function () {
					//console.log('images loaded');
					eventBus.publish(commonEvents.imagesLoaded);
					eventBus.publish(commonEvents.loaded);
				});

				return;
			}

			return $.ajax(
				{
					type: 'GET',
					url: url,
					timeout: settings.timeout
				})
				.done(function (response) {

					//console.log('content loaded from', url);

					eventBus.publish(commonEvents.pageLoaded, [url]);

					eventBus.publish(tracking.events.trackPage, [url]);

					title = replaceContent(response);

					if (title !== null) {
						if (!ignoreHistory) {
							eventBus.publish(history.events.push, [title, url, response]);
						}

						$(settings.contentSelector, $doc).imagesLoaded(function () {
							//console.log('images loaded');
							eventBus.publish(commonEvents.imagesLoaded);
							eventBus.publish(commonEvents.loaded);
						});
					}
					else {
						// Probably was an error...
						eventBus.publish(commonEvents.loaded);
					}

				})
				.fail(function (error) {
					//console.log('error', error);
					eventBus.publish(commonEvents.loaded);
					eventBus.publish(commonEvents.error, [ error.statusText, error.status ]);
				})
			;

		};

		return {
			initialize: initialize,
			load: load
		};

	})();

	var loadingScreen = (function () {

		var events = {
			showing: 'rw.loadingScreen.showing',
			shown: 'rw.loadingScreen.shown',
			hiding: 'rw.loadingScreen.hiding',
			hidden: 'rw.loadingScreen.hidden'
		};

		var defaults = {
			loaderSelector: '#loader',
			loaderMarkup: '<div id="loader"><div class="modal-backdrop fade"></div><div data-loader="timer"></div></div>'
		};

		var settings = {};

		var $loader = null;

		var loadingCount = 0;

		var initialize = function (options) {
			settings = $.extend({}, defaults, options);

			$loader = $(settings.loaderSelector);

			if ($loader.length !== 1) {

				//console.log('appended loader');

				$loader.remove();
				$('body').append(settings.loaderMarkup);
				$loader = $(settings.loaderSelector);
			}

			eventBus.subscribe(commonEvents.loading, show);
			eventBus.subscribe(commonEvents.loaded, hide);
		};

		var show = function () {
			//console.log('loading screen show', loadingCount);

			loadingCount++;

			if (loadingCount === 1) {
				eventBus.publish(events.showing);
				$loader
					.show()
					.children()
					.addClass('in')
					.delay(150)
					.queue(function (next) {
						//console.log('loading screen shown');
						eventBus.publish(events.shown);
						next();
					})
				;
			}
		};

		var hide = function () {
			loadingCount--;

			if (loadingCount === 0) {
				//console.log('loading screen hide');
				eventBus.publish(events.hiding);
				$loader
					.children()
					.removeClass('in')
					.delay(150)
					.queue(function (next) {
						//console.log('loading screen hidden');
						$loader.hide();
						eventBus.publish(events.hidden);
						next();
					})
				;
			}
		};

		return {
			events: events,
			initialize : initialize,
			show: show,
			hide: hide
		};

	})();

	var errors = (function () {

		var defaults = {
		};

		var settings = {};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			// should additionally add this as a handler
			//window.onerror = log;

			// should we do log/show separatly as may want to log error but silently?
			eventBus.subscribe(commonEvents.error, showError);
		};

		var log = function (message) { //, url, line) {
			try {
				eventBus.publish(tracking.events.trackEvent, ['Javascript Error', message, null]);
			}
			catch (err) { }

			return true;
		};

		var showError = function (message, status) {
			//console.log('error', message, status);
			eventBus.publish(alert.events.show, [{ 'title': 'Error!', 'content': message, 'type' : alert.types.error }]);
		};

		return {
			initialize: initialize,
			log: log,
			showError : showError
		};

	})();

	var dialog = (function () {

		var events = {
			show: 'rw.dialog.show',
			showing: 'rw.dialog.showing',
			shown: 'rw.dialog.shown',
			hiding: 'rw.dialog.hiding',
			hidden: 'rw.dialog.hidden'
		};

		var defaults = {
			dialogSelector: '#dialog',
			titleSelector: '.modal-title',
			contentSelector: '.modal-body',
			dialogMarkup:
				'<div�id="dialog"�class="modal�fade"�tabindex="-1"�role="dialog"�aria-labelledby="model-label">' + 
				'        <div�class="modal-dialog"�role="document">' + 
				'                <div�class="modal-content">' + 
				'                        <div�class="modal-header">' + 
				'                                <button�type="button"�class="close"�data-dismiss="modal"�aria-label="Close"><span�aria-hidden="true">&times;</span></button>' + 
				'                                <h4�class="modal-title"�id="model-label">Modal�title</h4>' + 
				'                        </div>' + 
				'                        <div�class="modal-body">' + 
				'                        </div>' + 
				'                        <div�class="modal-footer">' + 
				'                                <button�type="button"�class="btn�btn-default"�data-dismiss="modal">Close</button>' + 
				'                                <button�type="button"�class="btn�btn-primary">Save�changes</button>' + 
				'                        </div>' + 
				'                </div>' + 
				'        </div>' + 
				'</div>'
		};

		var settings = {};

		var $dialog = null;
		var $title = null;
		var $content = null;

		var initialize = function (options) {
			settings = $.extend({}, defaults, options);

			$dialog = $(settings.dialogSelector);

			if ($dialog.length === 0) {
				$('body').append(settings.dialogMarkup);
				$dialog = $(settings.dialogSelector);
			}

			$title = $(settings.titleSelector, $dialog);
			$content = $(settings.contentSelector, $dialog);

			eventBus.subscribe(events.show, show);

			$dialog.on('show.bs.modal', function (e) {
				eventBus.publish(events.showing, e);
			});
			$dialog.on('shown.bs.modal', function (e) {
				eventBus.publish(events.shown, e);
			});
			$dialog.on('hide.bs.modal', function (e) {
				eventBus.publish(events.hiding, e);
			});
			$dialog.on('hidden.bs.modal', function (e) {
				eventBus.publish(events.hidden, e);
			});
		};

		var show = function (state) {
			$title.html(state.title);
			$content.html(state.content);
			$dialog.modal('show');
		};

		var hide = function () {
			$dialog.modal('hide');
		};

		return {
			events: events,
			initialize: initialize,
			show: show,
			hide: hide
		};

	})();

	var alert = (function () {

		var events = {
			show: 'rw.alert.show',
			showing: 'rw.alert.showing',
			shown: 'rw.alert.shown',
			hiding: 'rw.alert.hiding',
			hidden: 'rw.alert.hidden'
		};

		var types = {
			primary: 'alert-primary',
			success: 'alert-success',
			info: 'alert-info',
			warning: 'alert-warning',
			error: 'alert-danger'
		};

		var defaults = {
			alertSelector: '#alert',
			titleSelector: '.alert-title',
			contentSelector: '.alert-content',
			alertMarkup: '<div id="alert" class="alert xfade alert-fixed alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong class="alert-title">Error!</strong> <span class="alert-content">There was an error</span></div>'
		};

		var settings = {};

		var $alert = null;
		var $title = null;
		var $content = null;

		var initialize = function (options) {
			settings = $.extend({}, defaults, options);

			//initMarkup();

			eventBus.subscribe(events.show, show);
			eventBus.subscribe(dialog.events.show, show);
		};

		var initMarkup = function (type) {
			$alert = $(settings.alertSelector);

			if ($alert.length === 0) {
				$('body').append(settings.alertMarkup);
				$alert = $(settings.alertSelector);

				$title = $(settings.titleSelector, $alert);
				$content = $(settings.contentSelector, $alert);

				$alert.on('close.bs.alert', function (e) {
					eventBus.publish(events.hiding, e);
				});
				$alert.on('closed.bs.alert', function (e) {
					eventBus.publish(events.hidden, e);
				});
			}
			else {
				$alert
					.removeClass(types.primary)
					.removeClass(types.success)
					.removeClass(types.info)
					.removeClass(types.warning)
					.removeClass(types.error)
				;
			}

			$alert.addClass(type);
		};

		var show = function (state) {

			eventBus.publish(events.showing);
			initMarkup(state.type);

			$title.html(state.title);
			$content.html(state.content);

			$alert
				.alert()
				.css('z-index', 1050)
				.delay(150)
				.queue(function (next) {
					eventBus.publish(events.shown);
					next();
				})
			;
		};

		var hide = function () {
			$alert.alert('close');
		};

		return {
			events: events,
			types: types,
			initialize: initialize,
			show: show,
			hide: hide
		};

	})();

	var masonry = (function () {

		var events = {
		};

		var defaults = {
		};

		var settings = {};

		var initPlugin = function () {
			//console.log('init masonry');

			var $masonryRows = $('.row.masonry');

			if ($masonryRows.length !== 0) {
				eventBus.publish(commonEvents.loading);

				var $grid = $masonryRows.masonry({
					isInitLayout: false,
					itemSelector: '.col-xs-12',
					columnWidth: '.col-xs-12',
					percentPosition: true
				});

				$grid.on('layoutComplete', function (event, items) {
					//console.log('masonry layoutComplete');
					eventBus.publish(commonEvents.loaded);
				});

				$grid.masonry();
			}
		};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			eventBus.subscribe(commonEvents.imagesLoaded, initPlugin);
		};

		return {
			events : events, 
			initialize: initialize
		};

	})();

	var codeHighlight = (function () {

		var events = {
		};

		var defaults = {
		};

		var settings = {};

		var initPlugin = function () {
			$('pre code').each(function (i, block) {
				console.log('init pre code highlight plugin');
				hljs.highlightBlock(block);
			});
		};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			eventBus.subscribe(commonEvents.loaded, initPlugin);
		};

		return {
			events: events,
			initialize: initialize
		};

	})();

	var history = (function () {

		var events = {
			push: 'rw.history.push', 
			pop: 'rw.history.pop'
		};

		var defaults = {
		};

		var settings = {};

		var hasPushState = function () {
			return !!(window.history && window.history.pushState);
		};

		var push = function (title, url, content) {
			//console.log('history push', title, url, content.substring(0, 50));
			window.history.pushState({ title: title, url: url, content: content }, title, url);
		};

		var pop = function (e) {
			//console.log('history pop', e.state);
			eventBus.publish(events.pop, [ e.state ]);
		};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			if (hasPushState()) {
				eventBus.subscribe(events.push, push);
			}

			//$(window).on('popstate', loadPage);
			window.addEventListener('popstate', function (e) {
				//console.log('popstate', e);
				var state = e.state;
				if (state !== null) {
					//load content with ajax
					pop(e);
				}
			});


		};

		return {
			events : events, 
			initialize: initialize,
			hasPushState: hasPushState,
			push: push
		};

	})();

	var bingImageOfTheDay = (function () {

		var events = {
		};

		var defaults = {
		};

		var settings = {};

		var initPlugin = function () {

			eventBus.publish(commonEvents.loading);
			
			var $biotd = $('#bing-iotd');
			
			if ($biotd.length === 0)
			{
				eventBus.publish(commonEvents.loaded);
				return;
			}

			$.ajax(
				{
					type: 'GET',
					dataType: 'json',
					url: '/proxy?url=' + encodeURIComponent('http://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=1&pid=hp&video=1&nc=' + new Date().getTime()),
					timeout: 10000
				})
				.done(function (response) {

					var baseUrl = 'http://www.bing.com';
					var image = response.images[0];

					if ((image.vid) && (image.vid.sources) && (image.vid.sources.length !== 0)) {

						var video = image.vid;
						var videoHtml = '';

						videoHtml += '<video id="bing-iotd" class="fullscreen-background" autobuffer="" preload="auto" oncontextmenu="return false" autoplay loop poster="' + video.image + '">';

						for (var i = 0; i < video.sources.length; i++) {
							var source = video.sources[i];
							videoHtml += '<source src="' + source[2] + '" type=\'' + source[1] + '\'>';
						}

						videoHtml += '</video>';

						$biotd.replaceWith($(videoHtml));
					}
					else {
						var imageUrl = baseUrl + image.url;
						$biotd.replaceWith($('<div id="bing-iotd" class="fullscreen-background" style="background-image : url(' + imageUrl + ')"></div>'));
					}

					eventBus.publish(commonEvents.loaded);
				}).fail(function (error) {
					eventBus.publish(commonEvents.loaded);
					eventBus.publish(commonEvents.error, [error.statusText, error.status]);
				})
			;

		};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			eventBus.subscribe(commonEvents.contentReplaced, initPlugin);
		};

		return {
			events : events, 
			initialize: initialize
		};

	})();

	var selectNav = (function () {

		var events = {
		};

		var defaults = {
			navItemsSelector: '#navbar>ul>li>a:not(:external)'
		};

		var settings = {};

		var hostRegExp = null;
		var $navItems = null;

		var selectNavItem = function (url) {

			$navItems.parents('.active').removeClass('active');

			var $anchors = $navItems;

			var lowerUrl = url.toLowerCase();

			$anchors.filter(function () {

				var href = $(this).attr('href');

				if (href) {
					href = href.replace(hostRegExp, '').toLowerCase();

					if (href === '/') {
						return url === '/';
					}

					return lowerUrl.indexOf(href) === 0;
				}

				return false;

			}).parents('li').addClass('active');
		};

		var closeNavBars = function () {

			// Close navbar...
			$('.navbar-collapse.in').collapse('hide');

			/*
			// Close dropdowns...
			$('.dropdown.open').dropdown('toggle');
			*/

		};

		var initialize = function (options) {

			settings = $.extend({}, defaults, options);

			hostRegExp = new RegExp("//" + location.host + "($|/)");

			$navItems = $(settings.navItemsSelector);

			eventBus.subscribe(commonEvents.pageLoading, closeNavBars);
			eventBus.subscribe(commonEvents.pageLoaded, selectNavItem);
		};

		return {
			events : events, 
			initialize: initialize
		};

	})();

	$(function () {
		loadingScreen.initialize();
		tracking.initialize();
		errors.initialize();
		//dialog.initialize();
		alert.initialize();
		selectNav.initialize();
		masonry.initialize();
		codeHighlight.initialize();
		bingImageOfTheDay.initialize();
		history.initialize();
		pageLoader.initialize();

		$('body').on('click', 'a[rel=external],a:external', function (e) {
			e.preventDefault();
			window.open($(this).attr('href'));
		});

		/*
		var $window = $(window);
		var $wrapper = $('.wrapper');

		var windowScroll = function(e) {
			var scrollTop = $window.scrollTop();

			if (scrollTop > 147) {
				$wrapper.removeClass('wrapper-top');
			} else {
				$wrapper.addClass('wrapper-top');
			}
		};

		$window.on('scroll', debounce(windowScroll, 100));
		windowScroll();
		*/
	});

}($));